import React from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

import {
  externalTooltipHandler,
  generateEstimatedHomeValueChartData,
} from "../../shared/helpers/util";

const EstimatedHomeValueChart = ({
  propertyData,
  yearFilter,
  onClickRequestHomeValuation,
}) => {
  const { labels, chartValue, soldValue } = generateEstimatedHomeValueChartData(
    propertyData,
    yearFilter
  );

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
  );

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 2,
    plugins: {
      tooltip: {
        enabled: false,
        position: "nearest",
        external: (context) => externalTooltipHandler(context, soldValue),
      },

      legend: {
        display: true,
        position: "bottom",
        padding: 20,
        labels: {
          boxWidth: 0,
          font: {
            size: 13,
          },
          color: "rgba(47, 162, 185, 1)",
          wrap: true,
        },
      },
    },
    scales: {
      x: {
        display: true,
        grid: {
          display: false,
        },
        ticks: {
          maxRotation: 0,
          maxLines: 1,
          maxWidth: 100,
        },
      },
      y: {
        display: false,
      },
    },
    elements: {
      point: {
        radius: 0,
        pointLabels: {
          display: false,
        },
      },
    },
  };

  const chartData = {
    labels: labels.reverse(),
    borderSkipped: false,

    datasets: [
      {
        label: "",
        data: chartValue.reverse(),
        pointRadius: 2,
        lineTension: 0.5,
        pointBorderWidth: 6,
        fill: true,
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 300);
          gradient.addColorStop(0, "rgba(47, 162, 185, 0.12)");
          gradient.addColorStop(1, "rgba(47, 162, 185, 0)");
          return gradient;
        },
        borderColor: "#2FA2B9",
      },
    ],
  };

  return (
    <>
      <div className="relative h-[180px] canvas_block">
        {(labels.length && propertyData?.chart_data?.length) === 0 ? (
          <div className="absolute top-20 right-0 left-0 text-center">
            No graph data available
          </div>
        ) : (
          <>
            <Line options={options} data={chartData} />
            <button
              type="button"
              onClick={
                onClickRequestHomeValuation && onClickRequestHomeValuation
              }
              className="text-[#2FA2B9] absolute bottom-0 left-0 right-0 text-[13px] font-medium text-center font-manrope z-[2]"
            >
              Request a more detailed home valuation
            </button>
          </>
        )}
      </div>
    </>
  );
};
export default EstimatedHomeValueChart;
