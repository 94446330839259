import React from "react";

import { useQuery } from "@tanstack/react-query";

import style from "../../../../assets/css/homeowner/home.module.css";

import { getTodayMortgageRate } from "../../../../shared/api/homehub";
import { getCurrentDate } from "../../../helpers/util";
import Loader from "../../../../shared/components/loader/Loader";

import cross from "../../../../assets/images/closeSideMenu.svg";

const InterestRatesModal = ({ onCloseInterestRatesModal }) => {
  const { data: mortgageRateData, isLoading } = useQuery(
    ["get-today-mortgage"],
    () => getTodayMortgageRate(),
    {
      select: (res) => {
        return res.data.data;
      },
    }
  );

  return (
    <div className="modal-card py-6 !pb-4 px-16 !max-w-[42.875rem] maxMd:!max-w-[calc(100%-30px)]">
      <button
        type="button"
        onClick={onCloseInterestRatesModal}
        className="absolute top-0 right-3.5 text-gray-400 mt-4 mb-4 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center transition-all outline-0"
        data-modal-toggle="staticModal"
      >
        <img src={cross} alt="cross" width={16} />
      </button>

      {isLoading ? (
        <Loader className="flex justify-center items-center h-full" />
      ) : (
        <div className="text-center max-w-[27.3125rem] mx-auto">
          <h2 className="text-prussianBlue text-2xl">Today's Interest Rates</h2>
          <div className={style.interestRateList}>
            <div>
              <h2 className={style.interestRateTitle}>
                30<small className="text-sm">Year</small>
              </h2>
              <span className={style.interestRatePointCounts}>Fixed Rate</span>
            </div>
            <div className={style.interestRateTitle_Inner}>
              <span className={style.interestRateSubTitle}>Interest Rate</span>
              <h2 className={style.interestRateCounts}>
                {mortgageRateData?.interest_rate_30_year || "0"}%
              </h2>
            </div>
            {/* <div className={style.interestRateTitle_Inner}>
              <span className={style.interestRateSubTitle}>APR</span>
              <h2 className={style.interestRateCounts}>
                {mortgageRateData?.year_apr_30_year || "0"}%
              </h2>
            </div>
            <div className={style.interestRateTitle_Inner}>
              <span className={style.interestRateSubTitle}>Points</span>
              <h2 className={style.interestRatePointCounts}>
                {mortgageRateData?.points_30_year || "0"}
              </h2>
            </div> */}
          </div>

          <div className={style.interestRateList}>
            <div>
              <h2 className={style.interestRateTitle}>
                15<small className="text-sm">Year</small>
              </h2>
              <span className={style.interestRatePointCounts}>Fixed Rate</span>
            </div>
            <div className={style.interestRateTitle_Inner}>
              <span className="text-xs text-prussianBlue">Interest Rate</span>
              <h2 className={style.interestRateCounts}>
                {mortgageRateData?.interest_rate_15_year || "0"}%
              </h2>
            </div>
            {/* <div className={style.interestRateTitle_Inner}>
              <span className="text-xs text-prussianBlue">APR</span>
              <h2 className={style.interestRateCounts}>
                {mortgageRateData?.apr_15_year || "0"}%
              </h2>
            </div>
            <div className={style.interestRateTitle_Inner}>
              <span className="text-xs text-prussianBlue">Points</span>
              <h2 className={style.interestRatePointCounts}>
                {mortgageRateData?.points_15_year || "0"}
              </h2>
            </div> */}
          </div>

          <div className="mt-8 pt-4 border-dotted border-t-[4px]">
            <h5 className="text-prussianBlue text-xs font-bold mb-2">
              RATE ASSUMPTIONS
            </h5>
            <p className="text-prussianBlue text-xs font-bold">
              Rates current as of: {getCurrentDate()}
            </p>
          </div>

          <div className="mt-10 flex gap-7 justify-center flex-wrap">
            <button className="btn-info font-medium hover:opacity-[0.8]">
              Get Pre-Approval
            </button>
            <button className="btn-info !bg-jacarta font-medium hover:opacity-[0.8]">
              Refinance my loan
            </button>
          </div>
          <p className="text-prussianBlue text-[0.5rem] mt-6 font-medium tracking-[1px]">
            All mortgage rate data is sourced from mortgagenewsdaily.com
          </p>
        </div>
      )}
    </div>
  );
};

export default InterestRatesModal;
