import {
  collection,
  addDoc,
  serverTimestamp,
  onSnapshot,
  query,
  orderBy,
  where,
  limit,
  getDocsFromServer,
  updateDoc,
  doc,
  increment,
  getDocs,
} from "firebase/firestore";
import {
  ADMIN,
  AGENT,
  HOMEOWNER,
  INVESTOR,
  SELLER,
  TYPE_IMAGE,
} from "../helpers/constants";
import { db, storage } from "../helpers/firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";

export const createChat = async (user1, user2, entity1, entity2) => {


  try {
    const chatData = {};

    // For user1: if entity1 is "agent", use user1.id as key, otherwise use entity1
    if (entity1 === "agent") {
      chatData[user1.id] = user1;
    } else {
      chatData[entity1] = user1;
    }

    // For user2: if entity2 is "agent", use user2.id as key, otherwise use entity2
    if (entity2 === "agent") {
      chatData[user2.id] = user2;
    } else {
      chatData[entity2] = user2;
    }

    // Add timestamp to the chat data

    chatData.timestamp = serverTimestamp();
    chatData.participant_1_id = `${entity1}_${user1.id}`;
    chatData.participant_2_id = `${entity2}_${user2.id}`;



    // Create a new chat document in the "chats" collection
    const chatDoc = await addDoc(collection(db, "chats"), chatData);

    return chatDoc; // Return the created document reference
  } catch (error) {
    console.error("Error creating chat:", error.message);
    throw error;
  }
}


export const sendMessage = async (chatId, message, senderType, userId) => {


  const senderKey = senderType === "agent" ? userId : senderType;

  await addDoc(collection(db, "chats", chatId, "messages"), {
    senderType,
    message,
    timestamp: serverTimestamp(),
    senderId: userId,
  });

  await updateByChatId(chatId, {
    [`${senderKey}.lastMessage`]: message,
    [`${senderKey}.unreadCount`]: increment(1),
    [`${senderKey}.is_reminded`]: false,
    [`${senderKey}.lastMessageTimestamp`]: serverTimestamp(),
    [`${senderKey}.isOnline`]: true,

    lastMessage: message,
    lastMessageTimestamp: serverTimestamp(),
  });
};

export const updateMessage = async (
  chatId,
  messageId,
  updateData,
  lastMessageId
) => {
  try {
    const messageRef = doc(db, "chats", chatId, "messages", messageId);

    // Exclude timestamp from updateData
    const { timestamp, ...dataToUpdate } = updateData;

    // Prepare the payload for updating the message
    const updatePayload = {
      ...dataToUpdate,
      isDelete: true,
      type: "message",
    };

    // Update the message
    await updateDoc(messageRef, updatePayload);

    // If the lastMessageId matches the current messageId, update the chat's last message
    if (lastMessageId === messageId) {
      const chatRef = doc(db, "chats", chatId);

      // Update the lastMessage field in the chat document
      await updateDoc(chatRef, {
        lastMessage: "Message was deleted",
        lastMessageTimestamp: serverTimestamp(),
      });
    }
    // Otherwise, only the message is updated and lastMessage remains unchanged
  } catch (error) {
    console.log(error);
    throw new Error("Error updating message");
  }
};

export const updateByChatId = (chatId, updateQuery) => {
  return updateDoc(doc(db, "chats", chatId), updateQuery);
};

export const getMessages = (
  chatId,
  loadingCallback,
  successCallback,
  failureCallback
) => {
  return onSnapshot(
    query(
      collection(db, "chats", chatId, "messages"),
      orderBy("timestamp", "asc")
    ),
    (querySnapshot) => {
      loadingCallback(false);
      const messages = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      successCallback(messages);
    },
    (error) => {
      failureCallback(error.message);
    }
  );
};

export const getChat = async (user1, user2, entity1, entity2) => {
  try {
    let user1Key, user2Key;

    if (entity1 === "agent" || entity2 === "agent") {
      user1Key = entity1 === "agent" ? user1.id : entity1;
      user2Key = entity2 === "agent" ? user2.id : entity2;
    } else {

      user1Key = entity1;
      user2Key = entity2;
    }


    const chatDocs = await getDocsFromServer(
      query(
        collection(db, "chats"),
        where(`${user1Key}.id`, "==", user1.id),
        where(`${user2Key}.id`, "==", user2.id),
        limit(1)
      )
    );
    return chatDocs;
  } catch (error) {
    console.error("Error getting chat:", error.message);
    throw error;
  }
};


export const getChatById = (
  chatId,
  loadingCallback,
  successCallback,
  failureCallback
) => {
  return onSnapshot(
    query(doc(db, "chats", chatId)),
    (querySnapshot) => {
      loadingCallback(false);
      const data = querySnapshot.data() || {};
      successCallback({ id: querySnapshot.id, ...data });
    },
    (error) => {
      failureCallback(error.message);
    }
  );
};

export const updateOnlineStatus = (chatId, role, status) => {
  let field = "";

  if (typeof role === "number") {
    // If role is a number, use it directly with .isOnline
    field = `${role}.isOnline`;
  } else {
    // Handle string-based roles
    switch (role) {
      case SELLER:
        field = "seller.isOnline";
        break;
      case INVESTOR:
        field = "investor.isOnline";
        break;
      case ADMIN:
        field = "admin.isOnline";
        break;
      case AGENT:
        field = "agent.isOnline";
        break;
      case HOMEOWNER:
        field = "homeowner.isOnline";
        break;
      default:
        break;
    }
  }

  // Ensure field is set before trying to update the document
  if (field) {
    updateDoc(doc(db, "chats", chatId), {
      [field]: status,
    });
  }
};




export const getChatList = (
  userId,
  userRole,
  loadingCallback,
  successCallback,
  failureCallback
) => {
  try {
    let queryFilters;

    // For AGENT role, check both participant fields
    if (userRole === AGENT) {
      const participantId = `${userRole}_${userId}`;

      const query1 = query(
        collection(db, "chats"),
        where("participant_1_id", "==", participantId),
        orderBy("lastMessageTimestamp", "desc")
      );

      const query2 = query(
        collection(db, "chats"),
        where("participant_2_id", "==", participantId),
        orderBy("lastMessageTimestamp", "desc")
      );

      // Execute both queries and merge results
      Promise.all([getDocs(query1), getDocs(query2)])
        .then(([snapshot1, snapshot2]) => {
          const messages = [];
          snapshot1.forEach(doc => messages.push({ id: doc.id, ...doc.data() }));
          snapshot2.forEach(doc => messages.push({ id: doc.id, ...doc.data() }));

          loadingCallback(false);
          successCallback(messages);
        })
        .catch(error => {
          console.error("Error fetching chats:", error);
          failureCallback(error.message);
        });

    } else {
      // For other roles (investor, seller, etc.)
      let roleField = "";
      switch (userRole) {
        case INVESTOR:
          roleField = "investor.id";
          break;
        case SELLER:
          roleField = "seller.id";
          break;
        case ADMIN:
          roleField = "admin.id";
          break;
        case HOMEOWNER:
          roleField = "homeowner.id";
          break;
        default:
          throw new Error("Invalid user role");
      }

      queryFilters = query(
        collection(db, "chats"),
        where(roleField, "==", userId),
        orderBy("lastMessageTimestamp", "desc")
      );

      // Set up a snapshot listener for the non-agent roles
      const unsubscribe = onSnapshot(
        queryFilters,
        (querySnapshot) => {
          loadingCallback(false);
          const messages = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data()
          }));
          successCallback(messages);
        },
        (error) => {
          console.error("Query error:", error);
          if (error.code === 'failed-precondition' && error.message.includes('index')) {
            console.log("Please create the index using this link:");
          }
          failureCallback(error.message);
        }
      );

      return unsubscribe; // Return the unsubscribe function
    }
  } catch (error) {
    console.error("Setup error:", error);
    loadingCallback(false); // Ensure loading is turned off on error
    failureCallback(error.message);
    return () => { }; // Return an empty function
  }
};



export const sendImageMessage = async (chatId, message, senderType, userId) => {
  await addDoc(collection(db, "chats", chatId, "messages"), {
    senderType,
    message,
    timestamp: serverTimestamp(),
    type: TYPE_IMAGE,
    senderId: userId
  });

  updateByChatId(chatId, {
    [`${senderType}.lastMessage`]: "Image",
    [`${senderType}.unreadCount`]: increment(1),
    [`${senderType}.is_reminded`]: false,
    [`${senderType}.lastMessageTimestamp`]: serverTimestamp(),
    [`${senderType}.isOnline`]: true,

    lastMessage: "Image",
    lastMessageTimestamp: serverTimestamp(),
  });
};

export const sendImage = async (chatId, file, senderType, userId) => {
  const res = await uploadBytes(ref(storage, `${chatId}/${uuidv4()}`), file);

  await addDoc(collection(db, "chats", chatId, "messages"), {
    senderType,
    message: await getDownloadURL(res.ref),
    timestamp: serverTimestamp(),
    type: TYPE_IMAGE,
    senderId: userId

  });

  updateByChatId(chatId, {
    [`${senderType}.lastMessage`]: "Image",
    [`${senderType}.unreadCount`]: increment(1),
    [`${senderType}.is_reminded`]: false,
    [`${senderType}.lastMessageTimestamp`]: serverTimestamp(),
    [`${senderType}.isOnline`]: true,

  });
};
